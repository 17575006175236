import React from "react";
import StatusHeader from "./StatusHeader";
import "./style.scss";
// import Headers from "../../../../common/header/Header";
// import Loader from "../../../../assets/img/loader.svg";

const PaymentInProcess = () => {
  return (
    <>
      <div className="status-container">
        <StatusHeader />
        <div className="status-contain">
          <div>
            {/* <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div> */}
            <div class="loading-dots">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
            {/* <img src={Loader} alt="abcd" /> */}
          </div>
          <h1 className="status-heading">Payment in process...</h1>
          <h5 className="status-subHeading">
            please do not press back button or refresh page...
          </h5>
        </div>
      </div>
    </>
  );
};

export default PaymentInProcess;
