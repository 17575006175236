import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useParams } from "react-router-dom";
import { CrossIcon, ErrorSymbol } from "../../../assets/img/svg";
import Header from "../../../common/header/Header";
import { GetOrderDetail } from "../service/service";
import PageNotFound from "../../../common/StatusPages/PageNotFound";
import PaymentInProcess from "../../../common/StatusPages/PaymentInProcess";
import PageUnderMaintenance from "../../../common/StatusPages/PageUnderMaintenance";
import "./style.scss";
import { Spinner } from "react-bootstrap";
import StaticStatusPage from "../../../common/StatusPages/StaticStatusPage";

const Transaction_Page1 = () => {
  let { id } = useParams();
  let [state, setState] = useState({});
  let [hide, setHide] = useState(true);
  let [load, setLoad] = useState(true);

  useEffect(() => {
    GetOrderDetail(id, state, setState, setLoad);
  }, []);

  return (
    <div className="page_wrapper">
      <div className="header-wrapper">
        <Header />

        <div className="header_bottom">
          <div className="header-bottom-contain">
            <div className="header_bottom_text">
              Aditya Birla Sun Life Insurance Company Limited
            </div>
          </div>
        </div>
      </div>

      {load ? (
        <div className="loaderdot">
          <div class="loading-dots">
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
          </div>
        </div>
      ) : (
        <>
          {state && state.success && (
            <div className="contains-wrapper">
              <div className="wrapper">
{hide && state.message && (
                      <div className="error-msg-container mobile">
                        <div className="error-symbol">
                          <ErrorSymbol />
                        </div>
                        <div className="error-message error-popup">
                          <h4>Transaction Attempt Failed</h4>
                          <div className="popup-msg">
                           <strong>Failure Reason:  {" "}</strong> 

                          {state && state.message}
                          </div>
                        </div>
                        <button
                          className="error-close-btn"
                          onClick={() => {
                            setHide(false);
                          }}
                        >
                          <span className="cross-svg">
                            <CrossIcon />
                          </span>
                        </button>
                      </div>
                    )}
                <div className="hor-top-contain-wrapper">
                  <div className="contain-heading">
                    <div className="welcometag">Welcome, </div>
                    <div className="customer-name">
                      {state &&
                        state.order_data &&
                        state.order_data.customer_name}
                    </div>
                  </div>

                  <div className="rightside">
                    {hide && state.message && (
                      <div className="error-msg-container web">
                        <div className="error-symbol">
                          <ErrorSymbol />
                        </div>
                        <div className="error-message error-popup">
                          <h4>Transaction Attempt Failed</h4>
                          <div className="popup-msg">
                           <strong>Failure Reason:  {" "}</strong> 

                          {state && state.message}
                          </div>
                        </div>
                        <button
                          className="error-close-btn"
                          onClick={() => {
                            setHide(false);
                          }}
                        >
                          <span className="cross-svg">
                            <CrossIcon />
                          </span>
                        </button>
                      </div>
                    )}
                    {state.expiry_time && (
                      <div className="timer">
                        <div className="circle-timer-container">
                          {/* state && (((Date.now()).getTime()/1000) - Math.floor(state.expiry_time/1000))} */}
                          <CountdownCircleTimer
                            className="circle-timer"
                            isPlaying
                            duration={
                              state &&
                              Math.floor(
                                state.expiry_time - new Date().getTime() / 1000
                              )
                            }
                            colors={["#d9908a"]}
                            children={900}
                            onComplete={() => {
                              window.location.reload();
                            }}
                          >
                            {({ remainingTime }) => {
                              const minutes = Math.floor(remainingTime / 60);
                              const seconds = remainingTime % 60;
                              return (
                                <span className="circle-timer-time">
                                  {minutes}:{seconds}
                                  <div>Left</div>
                                </span>
                              );
                            }}
                          </CountdownCircleTimer>
                        </div>
                        {state.attempt_left && (
                          <div className="attempt-count">
                            {state.attempt_left} attempt Left
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="summary-card-wrapper">
                  <div className="payment-gateway-header">
                    <div className="header-left-section">
                      <div className="header_title">Summary</div>
                    </div>
                    <div className="header-right-section">
                      <div className="header_amount">
                        {" "}
                        {state &&
                          state.order_data &&
                          !state.order_data.product_id_rslv.includes('~') && state.order_data.product_id_rslv}
                      </div>
                    </div>
                  </div>
                  <div className="card summary-card">
                    <div className="row_wrapper">
                      <div className="row tablerow1">
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="tableheader">Policy Owner Name</div>
                          <p className="tablecontent">
                            {state &&
                              state.order_data &&
                              state.order_data.customer_name}
                            {/* Hrishikesh kale */}
                          </p>
                        </div>
                        {/* <div className="col-3">
                  <p className="tableheader">Policy Owner Name</p>
                  <p className="tablecontent">
                    {state && state.order_data && state.order_data.customer_name}
                  </p>
                </div> */}
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="tableheader">Email id</div>
                          <p className="tablecontent">
                            {state &&
                              state.order_data &&
                              state.order_data.customer_email}
                            {/* hrishikalepatil@gmail.Com */}
                          </p>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="tableheader">Mobile Number</div>
                          <p className="tablecontent">
                            {state &&
                              state.order_data &&
                              state.order_data.customer_phone}
                            {/* 78887542727 */}
                          </p>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="tableheader">Amount</div>
                          <p className="tablecontent">
                            ₹
                            {state &&
                              state.order_data &&
                              state.order_data.amount}
                          </p>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="tableheader">Application/Policy Number</div>
                          <p className="tablecontent">
                            {state && state.order_data && state.order_data.pl_campaign_id?state.order_data.product_id:state.order_data.product_id_rslv}
                              
                            {/* 78887542727 */}
                          </p>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="tableheader">Joint Life Insured Name</div>
                          <p className="tablecontent">
                            -
                            {/* 78887542727 */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contrain-header-wrapper">
                  <div className="payment-gateway-header">
                    <div className="header-left-section">
                      <div className="header_title">Pay</div>
                    </div>
                    <div className="header-right-section">
                      <div className="header_amount">
                        {" "}
                        {state &&
                          state.order_data &&
                          "₹" + state.order_data.amount}
                      </div>
                    </div>
                  </div>

                  <div className="card pay-card">
                    {state && !state.inprogress ? ( // || state.attempt_data.status !== "FAILED" || state.attempt_data.status !== "SUCCESS") ?
                      <iframe
                        src={
                          state &&
                          state.attempt_data &&
                          state.attempt_data.pa_link_url
                        }
                        height="500"
                        title="Iframe Example"
                      ></iframe>
                    ) : (
                      <PaymentInProcess />
                      // <>
                      //   {state && state.attempt_data && (
                      //     <>
                      //       <p>
                      //         Transaction is{" "}
                      //         {state &&
                      //           state.attempt_data &&
                      //           state.attempt_data.status}
                      //         .
                      //       </p>
                      //       <p>
                      //         TRANSACTION ID:{" "}
                      //         {state &&
                      //           state.attempt_data &&
                      //           state.attempt_data.pa_id}
                      //       </p>
                      //       <p>
                      //         STATUS:{" "}
                      //         {state &&
                      //           state.attempt_data &&
                      //           state.attempt_data.pa_status}
                      //       </p>
                      //     </>
                      //   )}
                      // </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {state && !state.success && state.error && <StaticStatusPage error={state.error} />}
            {/* <PageNotFound />} */}
        </>
      )}
    </div>
  );
};

export default Transaction_Page1;
