import React from 'react'
import StatusFooter from './StatusFooter';
import StatusHeader from './StatusHeader';

const PageUnderMaintenance = () => {
  return (
    <div className='maintenace-wrapper'>
      <StatusHeader/>
      <h3 className='maintenace-heading'> Maintenance Page</h3>
      <p className='maintenance-subHeading'>
        {" "}
        Dear Customer, our site is under maintenance for technological
        improvisation to enhance customer experience. We shall be back soon with
        seamless payments/transactions and we promise to delight you.
        Inconvenience caused is regretted.
      </p>
      {/* <StatusFooter/> */}
    </div>
  );
}

export default PageUnderMaintenance