import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "./style.scss";
import { getResult } from "../../service/Service";
import dateFormat from "dateformat";
import StatusHeader from "../../../../common/StatusPages/StatusHeader";

const ResultPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("pl_order_id");

  let request_id = searchParams.get("request_id");
  let pl_order_id = searchParams.get("pl_order_id");
  let pl_attempt_id = searchParams.get("pl_attempt_id");
  let pa_txn_id = searchParams.get("pa_txn_id");
  let status = searchParams.get("status");
  let error_code = searchParams.get("error_code");
  let error_message = searchParams.get("error_message");
  let pa_txn_date = searchParams.get("pa_txn_date");
  let txn_amount = searchParams.get("txn_amount");
  return (
    <>
      {
        <>
          <StatusHeader />
          <div className="success-payment-contain-main">
            <div className="content-main">
              <div
                className={
                  status === "SUCCESS"
                    ? "content-header"
                    : "content-header-fail"
                }
              >
                {status === "SUCCESS" ? (
                  <>Payment Successfull!</>
                ) : (
                  <>Payment Failed!</>
                )}
              </div>

              {/* <div className="success-payment-logo">
          <svg
            width="100"
            height="100"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2136 2.66652C19.6141 2.1573 20.3854 2.1573 20.7859 2.66652L23.8006 6.50087C24.0513 6.81957 24.4694 6.95544 24.8594 6.8449L29.5521 5.51485C30.1754 5.33822 30.7996 5.79169 30.8241 6.43899L31.0093 11.3131C31.0248 11.7182 31.2831 12.0739 31.6638 12.2137L36.2419 13.896C36.8499 14.1195 37.0884 14.8532 36.7278 15.3913L34.0128 19.4434C33.7871 19.7802 33.7871 20.2199 34.0128 20.5567L36.7278 24.6087C37.0884 25.1469 36.8499 25.8805 36.2419 26.104L31.6638 27.7864C31.2831 27.9262 31.0248 28.2819 31.0093 28.687L30.8241 33.561C30.7996 34.2084 30.1754 34.6618 29.5521 34.4852L24.8594 33.1552C24.4694 33.0445 24.0513 33.1805 23.8006 33.4992L20.7859 37.3335C20.3854 37.8427 19.6141 37.8427 19.2136 37.3335L16.1989 33.4992C15.9484 33.1805 15.5302 33.0445 15.1401 33.1552L10.4474 34.4852C9.82415 34.6618 9.2 34.2084 9.17542 33.561L8.99022 28.687C8.97482 28.2819 8.71637 27.9262 8.33584 27.7864L3.75755 26.104C3.14954 25.8805 2.91114 25.1469 3.27172 24.6087L5.98679 20.5567C6.21245 20.2199 6.21245 19.7802 5.98679 19.4434L3.27172 15.3913C2.91114 14.8532 3.14954 14.1195 3.75755 13.896L8.33585 12.2137C8.71637 12.0739 8.97482 11.7182 8.99022 11.3131L9.17542 6.43899C9.2 5.79169 9.82415 5.33822 10.4474 5.51485L15.1401 6.8449C15.5302 6.95544 15.9484 6.81957 16.1989 6.50087L19.2136 2.66652Z"
              fill="#ECFDF5"
              stroke="#10B981"
              stroke-width="2"
            />
            <path
              d="M15 20L18.3333 23.3334L25 16.6667"
              stroke="#10B981"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div> */}
              <table className="table-contain-main">
                <tr className="success-table-row">
                  <td className="success-payment-left">Transaction ID :</td>
                  <td className="success-payment-right">{pa_txn_id}</td>
                </tr>
                <tr className="success-table-row">
                  <td className="success-payment-left">Order ID:</td>
                  <td className="success-payment-right">{pl_order_id}</td>
                </tr>
                <tr className="success-table-row">
                  <td className="success-payment-left">Amount :</td>
                  <td className="success-payment-right">{txn_amount}</td>
                </tr>
                {/* <tr className="success-table-row">
            <td className="success-payment-left">Payment Mode</td>
            <td className="success-payment-right">{state && state.attempt_data && state.attempt_data.amount}</td>
          </tr> */}
                <tr className="success-table-row">
                  <td className="success-payment-left">Date :</td>
                  <td className="success-payment-right">
                    {dateFormat(pa_txn_date, "dd-mm-yyyy hh:MM:ss")}
                  </td>
                </tr>
                <tr className="success-table-row">
                  <td className="success-payment-left">Status :</td>
                  <td className="success-payment-right">
                    <div
                      className={
                        status === "SUCCESS" ? "success-batch" : "danger-batch"
                      }
                    >
                      {status}
                    </div>
                  </td>
                </tr>
                {error_code !== undefined && error_code !== "" && (
                  <tr className="success-table-row">
                    <td className="success-payment-left">Error Code</td>
                    <td className="success-payment-right">
                      <div>{error_code}</div>
                    </td>
                  </tr>
                )}
                {error_message !== undefined && error_message !== "" && (
                  <tr className="success-table-row">
                    <td className="success-payment-left">Error message</td>
                    <td className="success-payment-right">{error_message}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default ResultPage;
