import { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProcessOrder from "../src/modules/pl_TransactionPage/component/ProcessOrder";
import ResultPage from "./modules/pl_DemoTestPage/component/ResultPage/ResultPage";
import TestPage from "./modules/pl_DemoTestPage/component/TestPage/TestPage";
import Transaction_Page1 from "./modules/pl_TransactionPage/component/Transaction_Page1";
import Transaction_Page2 from "./modules/pl_TransactionPage/component/Transaction_Page2";
import PaymentInProcess from "../src/common/StatusPages/PaymentInProcess";
import PageNotFound from "../src/common/StatusPages/PageNotFound";
// import NotFound from "./modules/pl_DemoTestPage/component/NotFoundPage/NotFound";
import Maintenance from "./modules/pl_DemoTestPage/component/Maintenance/Maintenance";
import HomePage from "./common/StatusPages/HomePage";
import StatusFooter from "./common/StatusPages/StatusFooter";
import PageUnderMaintenance from "./common/StatusPages/PageUnderMaintenance";
import CampaignPage from "./modules/pl_TransactionPage/component/CampaignPage";
import CampaignResultPage from "./modules/pl_TransactionPage/component/CampaignResultPage";
import InitialGen from "./modules/pl_TransactionPage/component/InitialGen";

let AppCtx = createContext();

function App() {
  let lob_id = window.location.pathname.split("/")[2];
  let payment_source_id = window.location.pathname.split("/")[3];
  let renderRoutes = () => (
    <>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/" element={<HomePage />} />
      {/* <Route path="/demo" element={<TestPage />} /> */}
      <Route path="/maintenanceerror" element={<PageUnderMaintenance />} />

      <Route path={`/resultpage`} element={<ResultPage />} />
      <Route path={`/campaign_resultpage`} element={<CampaignResultPage />} />

      <Route
        path={`/v2/${lob_id}/${payment_source_id}/:id`}
        element={<Transaction_Page1 />}
      />
      <Route
        path={`/v1/${lob_id}/${payment_source_id}/:id`}
        element={<Transaction_Page2 />}
      />
      <Route
        path={`/campaign/${lob_id}/${payment_source_id}/:enc_data`}
        element={<CampaignPage />}
      />
      <Route
        path={`/linkgen/${lob_id}/${payment_source_id}/:enc_data`}
        element={<InitialGen />}
      />
      <Route
        path={`/processOrder/${lob_id}/${payment_source_id}/:id`}
        element={<ProcessOrder />}
      />
      <Route path="/pip" element={<PaymentInProcess />} />
      <Route path="/pnf" element={<PageNotFound />} />
    </>
  );
  return (
    <AppCtx.Provider value={{}}>
      <Routes>{renderRoutes()}</Routes>
      <StatusFooter />
    </AppCtx.Provider>
  );
}

export default App;
export { AppCtx };
