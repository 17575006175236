import React from 'react'
import "./style.scss"
import ab_logo from "../../assets/img/ab_logo.png";

const Header = ({title}) => {
  return (
    <header>
      <div className="header-wrapper">
        <div className="header">
          <div className="contain">
            <div className="logo">
              <img src={ab_logo} />
            </div>
            <div>
              {title && <h1 className="page-heading">Demo Source Page</h1>}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header