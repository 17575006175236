import axios from "axios";
import { constants } from "../util/Constants";
let { host, uihost } = constants;

export let GetOrderDetail = (pl_order_id, state, setState, setLoad, final) => {
  axios
    .get(`${host}/api/web/getOrderData/${window.location.pathname.split("/")[2]}/${window.location.pathname.split("/")[3]}/${pl_order_id}`, {
      headers: {
        lob_id: window.location.pathname.split("/")[2],
        payment_source_id: window.location.pathname.split("/")[3],
      },
    })
    .then(function (response) {
      let data = response.data;
      setState(data);
      setLoad(false);
      if (data.return_url) {
        window.location.assign(data.return_url);
      }
    }).catch((error)=>{
      window.location.assign(`${uihost}/maintenanceerror`);
    });
};

export let getCampaignOrderDetail=(enc_data,state,setState,setattempt)=>{
  axios.get(`${host}/api/web/getCampaignOrderData/${window.location.pathname.split("/")[2]}/${window.location.pathname.split("/")[3]}/${window.location.pathname.split("/")[4]}`,{
    headers: {
      lob_id: window.location.pathname.split("/")[2],
      payment_source_id: window.location.pathname.split("/")[3],
    },
  }).then((res)=>{
    let data=res.data;
    setState(data);
    setattempt(res.data.attemptData);
  }).catch((error)=>{
    return error
  // window.location.assign(`${uihost}/maintenanceerror`);
  })

}

export let getCampaignOrderData=(pl_campaign_id,request_id,setPaymentData)=>{
  axios.get(`${host}/api/web/generateCampaignOrderWeb/${pl_campaign_id}/${request_id}`,{
    headers: {
      lob_id: window.location.pathname.split("/")[2],
      payment_source_id: window.location.pathname.split("/")[3],
    },
  }).then((res)=>{
    let data=res.data;
    setPaymentData(data);
  }).catch((error)=>{
    return error
    // window.location.assign(`${uihost}/maintenanceerror`);
  })

}

export let processOrder = (pl_order_id, state, setState, final) => {
  axios
    .get(`${host}/api/paresphandler/processRedirectResponse/${window.location.pathname.split("/")[2]}/${window.location.pathname.split("/")[3]}/${pl_order_id}`, {
      headers: {
        lob_id: window.location.pathname.split("/")[2],
        payment_source_id: window.location.pathname.split("/")[3],
      },
    })
    .then(function (response) {
      let data = response.data;
      setState(data);
      window.location.assign(data.return_url);
    });
};

export const createCampaignOrder = (enc_data) => {
  
  const headers = {
    lob_id: window.location.pathname.split("/")[2],
    payment_source_id: window.location.pathname.split("/")[3],
  };

  const requestBody={
    lob_id: window.location.pathname.split("/")[2],
    payment_source_id: window.location.pathname.split("/")[3],
  }

    axios.post(`${host}/api/web/generateCampaignOrder/${enc_data}`,requestBody,{
      headers:headers
    } )
    .then(function (response) {
      let data=response.data
     let url=JSON.stringify(response.data.response.links.web_url)
      window.location.assign(data.response.links.web_url);
    });
}
