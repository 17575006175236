import React, { useEffect, useState } from 'react'
import { Complete } from '../../../assets/img/svg'
import PaymentCompleteImg from '../../../../src/assets/img/complete.png'
import failedImg from "../../../../src/assets/img/failed.png"
import pendingImg from "../../../../src/assets/img/pending.png"
import {getCampaignOrderPayment} from "../service/service"

const LinkGenStatusCard = ({orderStatus,pa_txn_id,txn_amount, paymentData, attemptData}) => {

  return (
    <>
    { orderStatus==="SUCCESS" ?
      <div className="paymentStatusCard">
        <div className="img-wrapper">
          <img src={PaymentCompleteImg} alt="" />
        </div>
        <h5>Payment Completed</h5>
        <p>You have successfully paid INR {txn_amount}</p>
        <p>Transaction ID : {paymentData.plOrderData.pl_order_id}</p>
        <p>Net amount : {paymentData.attemptData[0]?.pa_txn_amount}</p>
     
      </div>
      :
      orderStatus==="FAILED" ? <div className="paymentStatusCard fail">
      <div className="img-wrapper">
        <img src={failedImg} alt="" />
      </div>
      <h5>Payment Failed</h5>
      <p>Transaction ID : {paymentData.plOrderData.pl_order_id}</p>
      <p>Net amount : {paymentData.attemptData[0]?.pa_txn_amount}</p>
   
      <p>Error Message : {paymentData.attemptData[0]?.pa_bank_error_message}</p>
      <p></p>
    </div>: 
      orderStatus==="PENDING" ? <div className="paymentStatusCard pending">
      <div className="img-wrapper ">
        <img src={pendingImg} alt="" />
      </div>
      <h5>Payment Pending</h5>  
      <p>Your payment is in pending state</p>
      <p>Transaction ID : {paymentData.plOrderData.pl_order_id}</p>
      <p>Net amount : { paymentData.attemptData[0]?.pa_txn_amount}</p>
   
    </div>: 
    orderStatus===false ?
      <div className="paymentStatusCard fail">
        <div className="img-wrapper">
          <img src={failedImg} alt="" />
        </div>
        <h5>Campaign Expired</h5>
       
      </div>
      :""
    
      }

</>

  )
}

export default LinkGenStatusCard